import { navigate } from '@reach/router';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import Tooltip from '../../../layouts/tooltip/Tooltip';
import Text from '../../../layouts/typography/Text';
import { Memo } from '../../../models/Memo';
import { getDateString, getDaysElapsed } from '../../../utils/date/Date';
import { DatatableComponentProps, ITableHeader } from '../datatable/Datatable';
import React from 'react';

const ActiveMemosTableBody = (props: DatatableComponentProps & { onUserAction: (...args: string[]) => void }) => {
    const goToEditForm = (memoId: string) => {
        navigate(`/memo/edit/${memoId}`);
    };

    if (!props.memos || !props.headers) {
        return <TableRow />;
    }
    return (
        <>
            {props.memos!.map((_memo: Memo) => {
                const memo = (_memo as unknown) as Record<string, any>;

                return (
                    <TableRow
                        key={memo.memo_id}
                        navigateTo={`/memos/active/${memo.memo_id}`}
                        data-testid="table-custom-body"
                    >
                        {props.headers!.map((header: ITableHeader, index) => {
                            if (['date_submitted', 'date_last_modified', 'date_created'].includes(header.key)) {
                                return (
                                    <TableBodyCell key={header.key + `${index}`}>
                                        {getDateString(memo[header.key])}
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'current_approver') {
                                if (!memo.current_approver[0]) {
                                    return (
                                        <TableBodyCell key={header.key + `${index}`}>
                                            No approvers attached
                                        </TableBodyCell>
                                    );
                                }
                                return (
                                    <TableBodyCell key={header.key + `${index}`}>
                                        {memo.current_approver && memo.current_approver.length > 1 ? (
                                            <div>
                                                {memo.current_approver[0].displayName}
                                                <Tooltip type="approvers" items={memo.current_approver}></Tooltip>
                                            </div>
                                        ) : (
                                            <div>{memo.current_approver[0].displayName}</div>
                                        )}
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell type="status" key={header.key + `${index}`}>
                                        <Status type={memo.memo_status?.toLowerCase()} />
                                        <Text color="secondary">
                                            Days elapsed: {getDaysElapsed(memo.date_last_modified)}
                                        </Text>
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'action') {
                                return (
                                    <TableBodyCell type="action" key={header.key + `${index}`}>
                                        <button
                                            type="button"
                                            className="p-1 inline-block align-middle text-sm text-blue underline m-1"
                                            onClick={() => {
                                                props.onUserAction('cancel', memo.memo_id, memo.current_sequence);
                                            }}
                                            data-testid="active-memo-item-cancel"
                                        >
                                            Cancel
                                        </button>
                                        {memo.memo_status?.toLowerCase() === 'submitted' ? (
                                            <button
                                                className="p-1 text-sm text-blue underline m-1"
                                                onClick={() => {
                                                    if (getDaysElapsed(memo.date_last_modified) < 3) {
                                                        props.onUserAction(
                                                            'remind_early',
                                                            memo.memo_id,
                                                            memo.current_sequence,
                                                            memo.current_approver,
                                                        );
                                                    } else {
                                                        props.onUserAction(
                                                            'remind',
                                                            memo.memo_id,
                                                            memo.current_sequence,
                                                            memo.current_approver,
                                                        );
                                                    }
                                                }}
                                                data-testid="active-memo-item-remind"
                                            >
                                                Remind
                                            </button>
                                        ) : (
                                            <button
                                                className="p-1 text-sm text-blue underline m-1"
                                                onClick={() => {
                                                    goToEditForm(memo.memo_id);
                                                }}
                                                data-testid="active-memo-item-edit"
                                            >
                                                Edit
                                            </button>
                                        )}
                                        {!['self_returned', 'returned'].includes(memo.memo_status?.toLowerCase()) && (
                                            <button
                                                type="button"
                                                className="p-1 inline-block align-middle text-sm text-blue underline m-1"
                                                onClick={() => {
                                                    props.onUserAction('withdraw', memo.memo_id, memo.current_sequence);
                                                }}
                                                data-testid="active-memo-item-withdraw"
                                            >
                                                Withdraw
                                            </button>
                                        )}
                                    </TableBodyCell>
                                );
                            }

                            const field = memo.find(header.key);

                            if (field) {
                                return <TableBodyCell key={memo[header.key] + `${index}`}>{field.value}</TableBodyCell>;
                            }

                            return (
                                <TableBodyCell key={memo[header.key] + `${index}`}>{memo[header.key]}</TableBodyCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};

export default ActiveMemosTableBody;
