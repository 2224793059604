import { navigate, useLocation } from '@reach/router';
import firebase from 'firebase/app';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Container from '../../../layouts/container/Container';
import Input from '../../../layouts/form/Input';
import TextArea from '../../../layouts/form/Textarea';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import { SelectItems } from '../../../layouts/select/Select';
import Tab from '../../../layouts/tab/Tab';
import TabLayout from '../../../layouts/tab/TabLayout';
import Subtitle from '../../../layouts/typography/Subtitle';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { Approver } from '../../../models/Approver';
import { ModalContent } from '../../../models/ModalContent';
import { ReminderRequest } from '../../../models/ReminderRequest';
import { Template } from '../../../models/Template';
import { setRemarks, updateMemoStatus } from '../../../redux/ApproverSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import { getDateString } from '../../../utils/date/Date';
import DatatableFilters from '../datatable/DatatableFilters';
import ActiveMemos from './ActiveMemos';
import MyDraftMemos from './DraftMemos';
import HistoryMemos from './HistoryMemos';

const MyMemos = () => {
    const dispatch: AppDispatch = useDispatch();
    const { remarks } = useSelector((state: RootState) => state.Approver);
    const [selectItems, setSelectItems] = React.useState<SelectItems[]>([]);
    const [actionMemoID, setActionMemoID] = React.useState('');
    const [actionCurrentSequence, setActionCurrentSequence] = React.useState(0);
    const { user } = useSelector((state: RootState) => state.Auth);
    const [selectedMemoType, setSelectedMemoType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [approversToRemind, setApproversToRemind] = React.useState<any[]>([]);
    const remoteConfig = firebase.remoteConfig();
    const location = useLocation();
    const [modalContent, setModalContent] = React.useState<ModalContent>({
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    React.useEffect(() => {
        // TODO: Write code to get templates here.
        let isMounted = true;
        const loadTemplate = async () => {
            try {
                await remoteConfig.fetchAndActivate();
                const getTemplates = firebase.app().functions('asia-east2').httpsCallable('get_templates');
                const http_response = await getTemplates({ user_id: user.uid });

                const response = http_response.data;
                if (response && Array.isArray(response)) {
                    const templates = response.map((item: Template | any) => {
                        return {
                            label: item.template_name,
                            value: item.template_id,
                            headers: item.headers,
                        };
                    });

                    if (isMounted) {
                        setSelectItems(templates);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        loadTemplate();

        return () => {
            isMounted = false;
            dispatch(setRemarks(''));
        };
    }, []);

    const resetPageNumber = () => {
        navigate(`${location.pathname}?page=1`);
    };

    /** Handles dropdown event change from Select component.
     */
    const onSelectChanged = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            setSelectedMemoType(event.currentTarget.value);
        }
        resetPageNumber();
    };

    /**
     * Handles datepicker event
     * @param event - React Datepicker event object
     */
    const onDateChanged = /* istanbul ignore next */ (event: any) => {
        const [start, end] = event;
        setStartDate(start);
        setEndDate(end);
        resetPageNumber();
    };

    /** logic for Download Report */
    const whichTable = /* istanbul ignore next */ (path: string) => {
        if (path === '/memos' || path === '/memos/active') {
            return 'active';
        } else if (path === '/memos/history') {
            return 'history';
        }
        return '';
    };

    /**
     * Function for Download Report
     */
    const onDownloadXLSXClick = async () => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            const downloadXLSX = firebase.app().functions('asia-east2').httpsCallable('createXlsxReport');
            const all_types = selectItems.map((item) => {
                return item.value;
            });

            const memo_types = selectedMemoType === '' || selectedMemoType === 'ALL' ? all_types : selectedMemoType;
            const memo_table = whichTable(location.pathname);
            const start_date: any = startDate;
            const end_date: any = endDate;

            const http_response = await downloadXLSX({
                memo_audience_type: 'memos',
                memo_table: memo_table,
                memo_types: memo_types,
                start_date: moment(start_date).toISOString(),
                end_date: moment(end_date).toISOString(),
            });
            const response = http_response.data;
            if (response.code === 'ok') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_check,
                    title: 'Report is being generated.',
                    subtitle: `<center>Check your email for the download link to the generated report.<br />It may take a few minutes to receive the email depending on the size of the report.</center>`,
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else if (response.code === 'not-found') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.message,
                    subtitle: response.details ? response.details : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.code,
                    subtitle: response.message ? response.message : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            }
        } catch (err: any) {
            console.error(err);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: `${err.code}: Unable to generate report.`,
                subtitle: `${err.message}. <br> Please contact memo-support@globe.com.ph`,
                buttons: [
                    {
                        label: 'Back to the list',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    /**
     * Function for getting approver details prior sending reminders
     * @param memo_id
     * @param current_sequence
     * @param approvers
     * @returns an array of approver objects with consolidated information
     */
    const getApproverDetailsForReminder = async (
        memo_id: string,
        current_sequence: number,
        approvers: Array<Approver & { uid: string; email: string }>,
    ) => {
        const getApproverDetails = firebase
            .app()
            .functions('asia-east2')
            .httpsCallable('get_approver_details_for_reminder');

        /** retrieve approver documents */
        const http_response = await getApproverDetails({
            memo_id,
            current_sequence: current_sequence.toString(),
        });
        const response = http_response.data;

        /** concatenate respective approver info from memo document, approver document and send reminder flag*/
        const newApproversArray = response.approvers.map((approverDoc: ReminderRequest) => {
            const found = approvers.find(
                (approver) =>
                    approver.uid === approverDoc.approver_user_id || approver.email === approverDoc.approver_user_id,
            );
            return { ...approverDoc, ...found };
        });
        return newApproversArray as ReminderRequest[];
    };

    /**
     * onClick function for un/checking approvers
     */
    let remindEmail: any = [];
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            remindEmail.push({ approver_id: event.currentTarget.value, approver_email: event.currentTarget.id });
        } else {
            if (remindEmail.length === 1) {
                remindEmail = [];
            } else {
                const indexToRemove = remindEmail.findIndex(
                    (elem: any) => elem === { id: event.currentTarget.value, email: event.currentTarget.id },
                );
                remindEmail.splice(indexToRemove, 1);
            }
        }
        setApproversToRemind(remindEmail);
    };

    /**
     * Handles change event for remarks field
     */
    const onRemarksChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setRemarks(e.currentTarget.value));
    };

    /**
     * Opens a modal for reminding approvers
     */
    const openRemindApproverModal = async (
        memo_id: string,
        current_sequence: number,
        approvers: Array<Approver & { uid: string; email: string }>,
    ) => {
        try {
            /** for reminding approvers */
            const approverDetails = await getApproverDetailsForReminder(memo_id, current_sequence, approvers);

            /** Single approver with no reminders yet  */
            if (
                approverDetails.length === 1 &&
                (approverDetails[0].has_reminder_today === false ||
                    approverDetails[0].has_reminder_today === null ||
                    approverDetails[0].has_reminder_today === undefined)
            ) {
                setApproversToRemind([approverDetails[0].approver_id, approverDetails[0].email]);

                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: 'Send reminder',
                    extras: 'remind_solo_approver',
                    subtitle: `<center>A reminder email will be sent to the current approver. Are you sure you want to proceed?.</center>`,
                    buttons: [
                        {
                            label: 'No',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                setApproversToRemind([]);
                            },
                        },
                        {
                            label: 'Yes',
                            event: /* istanbul ignore next */ () => {
                                return;
                            },
                        },
                    ],
                });
            } else if (approverDetails.every((elem: any) => elem.has_reminder_today === true)) {
                /** Single approver with reminder already  */
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: 'Oops, one reminder per day only!',
                    subtitle: `<center>You have already sent a reminder email today</center>`,
                    buttons: [
                        {
                            label: 'Okay, got it!',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else {
                /** Multiple approvers */
                setModalContent({
                    isVisible: true,
                    loading: false,
                    title: 'Send reminder',
                    extras: 'remind_multi_approver',
                    subtitle: `Select which of the current approvers who have not yet responded you would like to send a reminder email to:`,
                    extraComponent: (
                        <div>
                            <ul>
                                {approverDetails.map((approver: any, index: number) => {
                                    const has_reminder_today = approver.has_reminder_today;
                                    return (
                                        <li key={approver.uid + index} data-testid="multi-approver-item">
                                            <Input
                                                type="checkbox"
                                                id={approver.email}
                                                label={
                                                    approver.date_reminded
                                                        ? `${approver.displayName} - Reminded on ${getDateString(
                                                              approver.date_reminded,
                                                          )}`
                                                        : approver.approval_status === 'APPROVED'
                                                        ? `${approver.displayName} - Approved`
                                                        : approver.displayName
                                                }
                                                onChange={onCheckboxChange}
                                                disabled={
                                                    approver.approval_status === 'APPROVED'
                                                        ? true
                                                        : has_reminder_today === false || has_reminder_today === null
                                                        ? false
                                                        : true
                                                }
                                                value={approver.approver_id}
                                                data-testid="multi-approver-item-option"
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ),
                    buttons: [
                        {
                            label: 'No',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                setApproversToRemind([]);
                            },
                        },
                        {
                            label: 'Yes',
                            event: /* istanbul ignore next */ () => {
                                return;
                            },
                        },
                    ],
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Opens a modal for cancelling a memo
     */
    const openCancelModal = () => {
        /** for cancelling a memo */
        setModalContent({
            ...modalContent,
            isVisible: true,
            loading: false,
            title: 'Cancel this memo?',
            subtitle: `Please provide remarks below.`,
            extras: 'withdraw_memo',
            extraComponent: (
                <TextArea
                    id="txt-reason"
                    label="Remarks"
                    rows={4}
                    placeholder="Type your remarks here."
                    onChange={onRemarksChanged}
                    data-testid="textarea-remarks"
                />
            ),
            buttons: [
                {
                    label: 'Close',
                    event: () => {
                        setModalContent({ ...modalContent, isVisible: false });
                        dispatch(setRemarks(''));
                    },
                },
                {
                    label: 'Proceed',
                    event: () => {
                        return;
                    },
                },
            ],
        });
    };

    /**
     * Opens a modal for withdraw memo.
     */
    const openWithdrawModal = () => {
        setModalContent({
            ...modalContent,
            isVisible: true,
            loading: false,
            icon: ic_alert,
            title: 'Withdraw this memo?',
            subtitle: `Are you sure you want to withdraw this memo?`,
            extras: 'self_return_memo',
            buttons: [
                {
                    label: 'Close',
                    event: () => {
                        setModalContent({ ...modalContent, isVisible: false });
                    },
                },
                {
                    label: 'Proceed',
                    event: () => {
                        return;
                    },
                },
            ],
        });
    };

    /**
     * Opens a modal that says it's too soon.
     */
    const openTooSoonModal = () => {
        /** Too early to send reminders yet */
        setModalContent({
            isVisible: true,
            loading: false,
            icon: ic_alert,
            title: 'Hang on, it’s too soon to send a reminder!',
            subtitle: `<center>A reminder email can only be sent when memo has been pending with current approver for 3 days </center>`,
            buttons: [
                {
                    label: 'Okay, got it!',
                    event: () => setModalContent({ ...modalContent, isVisible: false }),
                },
            ],
        });
    };

    /**
     * Handles action button on active memo table
     */
    const onUserAction = async (action: string, memo_id: string, current_sequence: number, approvers?: any) => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            setActionMemoID(memo_id);
            setActionCurrentSequence(current_sequence);

            switch (action) {
                case 'cancel':
                    openCancelModal();
                    break;
                case 'withdraw':
                    openWithdrawModal();
                    break;
                case 'remind':
                    await openRemindApproverModal(memo_id, current_sequence, approvers);
                    break;
                case 'remind_early':
                    openTooSoonModal();
                    break;
            }
        } catch (err) {
            console.error(err);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: `catch`,
                subtitle: `Please contact memo-support@globe.com.ph`,
                buttons: [
                    {
                        label: 'Back to the list',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    /**
     * Sends payload to CFs for respective User Actions
     * @param event
     */
    const sendModalRequest = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setModalContent({ ...modalContent, loading: true });
            /** CANCEL MEMO */
            if (user.uid && actionMemoID && ['withdraw_memo', 'self_return_memo'].includes(modalContent.extras)) {
                const payload = {
                    user_id: user.uid,
                    memo_id: actionMemoID,
                    remarks: remarks,
                    action: modalContent.extras,
                    sequence_no: actionCurrentSequence,
                };
                const response = await dispatch(updateMemoStatus(payload));
                const status = response.meta.requestStatus;
                if (status === 'fulfilled') {
                    switch (modalContent.extras) {
                        case 'withdraw_memo':
                            setModalContent({
                                isVisible: true,
                                loading: false,
                                icon: ic_check,
                                title: 'Memo cancelled',
                                subtitle: `<center>Your memo has been cancelled. You may revisit previous memos in My Memos > <b>History</b> tab.</center>`,
                                buttons: [
                                    {
                                        label: 'Back to my memos',
                                        event: () => navigate('/memos/active'),
                                    },
                                ],
                            });
                            break;
                        case 'self_return_memo':
                            setModalContent({
                                isVisible: true,
                                loading: false,
                                icon: ic_check,
                                title: 'Memo withdrawn',
                                subtitle: `<center>Your memo has been withdrawn.`,
                                buttons: [
                                    {
                                        label: 'Back to my memos',
                                        event: () => navigate('/memos/active'),
                                    },
                                ],
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    throw new Error('There was an error while sending the request. Please try again later.');
                }
            } else if (user.uid && actionMemoID && ['remind_solo_approver'].includes(modalContent.extras)) {
                try {
                    const payload = {
                        approver_id: approversToRemind[0],
                        approver_email: approversToRemind[1],
                    };
                    const remindCurrentApprover = firebase
                        .app()
                        .functions('asia-east2')
                        .httpsCallable('remindCurrentApprover');
                    const http_response = await remindCurrentApprover(payload);
                    const response = http_response.data;
                    setModalContent({ ...modalContent, loading: true });

                    if (response.code === 'ok') {
                        switch (modalContent.extras) {
                            case 'remind_solo_approver':
                                setModalContent({
                                    isVisible: true,
                                    loading: false,
                                    icon: ic_check,
                                    title: 'Reminder sent',
                                    subtitle: `<center>Reminder email has been sent to the current approver.</center>`,
                                    buttons: [
                                        {
                                            label: 'Okay, got it!',
                                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                                        },
                                    ],
                                });
                                setApproversToRemind([]);
                                break;
                            default:
                                break;
                        }
                    } else {
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_alert,
                            title: `Error ${response.code}`,
                            subtitle: response.message,
                            buttons: [
                                {
                                    label: 'Okay, got it!',
                                    event: () => setModalContent({ ...modalContent, isVisible: false }),
                                },
                            ],
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            } else if (user.uid && actionMemoID && ['remind_multi_approver'].includes(modalContent.extras)) {
                const remindCurrentApprover = firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('remindCurrentApprover');

                const statuses = approversToRemind.map(async (approver) => {
                    const http_response = await remindCurrentApprover(approver);
                    const response = http_response.data;
                    return await response.code;
                });

                const returnedStatuses = await Promise.all(statuses);

                /** will need this logging for next iteration of checking failed reminders */

                // console.log(
                //     'returnedStatuses every',
                //     returnedStatuses.every((elem) => elem === 200),
                // );
                // console.log('returnedStatuses', returnedStatuses);
                // setModalContent({ ...modalContent, loading: true });

                if (
                    returnedStatuses &&
                    returnedStatuses.length !== 0 &&
                    returnedStatuses.every((elem) => elem === 'ok')
                ) {
                    switch (modalContent.extras) {
                        case 'remind_multi_approver':
                            setModalContent({
                                isVisible: true,
                                loading: false,
                                icon: ic_check,
                                title: 'Reminder sent',
                                subtitle: `<center>Reminder email has been sent to the selected approvers.</center>`,
                                buttons: [
                                    {
                                        label: 'Okay, got it!',
                                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                                    },
                                ],
                            });
                            setApproversToRemind([]);
                            break;
                        default:
                            break;
                    }
                } else if (returnedStatuses && returnedStatuses.length === 0) {
                    setModalContent({
                        isVisible: true,
                        loading: false,
                        icon: ic_alert,
                        title: `Oops, something has happened!`,
                        subtitle: `A reminder was not sent. Please try again`,
                        buttons: [
                            {
                                label: 'Okay, got it!',
                                event: () => setModalContent({ ...modalContent, isVisible: false }),
                            },
                        ],
                    });
                } else {
                    setModalContent({
                        isVisible: true,
                        loading: false,
                        icon: ic_alert,
                        title: `Oops, something has happened!`,
                        subtitle: `Please contact memo-support@globe.com.ph`,
                        buttons: [
                            {
                                label: 'Okay, got it!',
                                event: () => setModalContent({ ...modalContent, isVisible: false }),
                            },
                        ],
                    });
                }
            }
        } catch (err) {
            console.log(err);
            alert(err);
            setModalContent({ ...modalContent, isVisible: false });
        }
    };

    /**
     * TODO: Refactor redundant code.
     */
    return (
        <Container>
            <div className="w-full">
                <Title>My memos</Title>
                <div className="my-2">
                    <Subtitle>Manage your memos</Subtitle>
                </div>
            </div>
            <div className="w-full mt-8">
                <TabLayout>
                    <Tab label="Active memos" href="/memos">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <ActiveMemos
                                memo_type={selectedMemoType}
                                start_date={startDate}
                                end_date={endDate}
                                onUserAction={onUserAction}
                            />
                        </div>
                    </Tab>
                    <Tab label="Drafts" href="/memos/drafts">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={false}
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <MyDraftMemos memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                    <Tab label="History" href="/memos/history">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <HistoryMemos memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                </TabLayout>
            </div>
            <Modal visible={modalContent.isVisible ? modalContent.isVisible : false}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Preloader type="dots" text="Loading..." />
                    </div>
                ) : (
                    <form className="w-full" onSubmit={sendModalRequest}>
                        <fieldset>
                            <div
                                className={(() => {
                                    if (modalContent.icon) {
                                        return `w-full flex flex-col items-center`;
                                    } else {
                                        return 'w-full flex flex-col items-start';
                                    }
                                })()}
                                data-testid="modal-content"
                            >
                                {modalContent.icon && (
                                    <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                                )}
                                <Title type="h4">{modalContent.title}</Title>
                                <div className={`${modalContent.icon ? 'my-2' : 'my-0'}`}>
                                    <Text type="innerHTML">{modalContent.subtitle}</Text>
                                </div>
                                {modalContent.extraComponent && (
                                    <div className="w-full mt-4">{modalContent.extraComponent}</div>
                                )}
                                {(() => {
                                    if (modalContent.buttons.length > 1) {
                                        return (
                                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                                <div className="w-full flex flex-col px-2">
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        weight="font-regular"
                                                        onClick={modalContent.buttons[0].event}
                                                        data-testid="modal-button-secondary"
                                                    >
                                                        {modalContent.buttons[0].label}
                                                    </Button>
                                                </div>
                                                <div className="w-full flex flex-col px-2">
                                                    <Button
                                                        color="primary"
                                                        weight="font-regular"
                                                        type="submit"
                                                        disabled={
                                                            remarks === '' &&
                                                            ![
                                                                'approve_memo',
                                                                'delete_draft',
                                                                'withdraw_memo',
                                                                'self_return_memo',
                                                                'remind_solo_approver',
                                                                'remind_multi_approver',
                                                            ].includes(modalContent.extras)
                                                        }
                                                        onClick={modalContent.buttons[1].event}
                                                        data-testid="modal-button-primary"
                                                    >
                                                        {modalContent.buttons[1].label}
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="my-2">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    weight="font-regular"
                                                    onClick={modalContent.buttons[0].event}
                                                >
                                                    {modalContent.buttons[0].label}
                                                </Button>
                                            </div>
                                        );
                                    }
                                })()}
                            </div>
                        </fieldset>
                    </form>
                )}
            </Modal>
        </Container>
    );
};

export default MyMemos;
