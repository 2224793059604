import { navigate } from '@reach/router';
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import firebase from 'firebase/app';
import _, { update } from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Container from '../../../layouts/container/Container';
import Datepicker from '../../../layouts/datepicker/Datepicker';
import DropZone from '../../../layouts/form/DropZone';
import Input from '../../../layouts/form/Input';
import Label from '../../../layouts/form/Label';
import TextArea from '../../../layouts/form/Textarea';
import Guidelines from '../../../layouts/guidelines/Guidelines';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Select, { SelectItems } from '../../../layouts/select/Select';
import Subtitle from '../../../layouts/typography/Subtitle';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { Memo } from '../../../models/Memo';
import { MemoContent } from '../../../models/MemoContent';
import { ModalContent } from '../../../models/ModalContent';
import { Sequence } from '../../../models/Sequence';
import { TempFile } from '../../../models/TempFile';
import { Template } from '../../../models/Template';
import { TemplateCondition, TemplateConstant, TemplateContent } from '../../../models/TemplateContent';
import {
    clearForm,
    setMode,
    setSequences,
    setTemplate,
    setTemplateLoadState,
    submitMemo,
    updateMemo,
} from '../../../redux/MemoFormV2Slice';
import { RootState } from '../../../redux/RootReducer';
import Store, { AppDispatch } from '../../../redux/Store';
import { uploadFiles } from '../../../utils/googleapis/StorageAPI';
import Editor from './Editor';
import { SequenceProvider } from './hoc/SequenceProvider';
import { UploadProvider } from './hoc/UploadProvider';
import SequenceTree from './SequenceTree';
import StaticGrid from './StaticGrid';
import ic_cog from '../../../assets/img/ic_cog.svg';
import { evalTruthList } from '../../../utils/misc/evalTruthList';

const FileUpload = UploadProvider(DropZone);
const ApprovalWorkflow = SequenceProvider(SequenceTree);

interface IMemoFormProps {
    /**
     * Sets the form either in CREATE or in EDIT mode.
     */
    mode?: string;
    /**
     * The unique identifier of the template to be rendered.
     */
    template_id?: string;

    /**
     * The unique identifier of the memo to be edited
     */
    memo_id?: string;
}

/**
 * The component that is responsible for creating and editing memos based on a rendered template.
 * @returns {JSX.Element}
 */
const MemoForm: React.FunctionComponent<IMemoFormProps> = (props: IMemoFormProps) => {
    /**
     * Props passed from routing
     */
    const { mode, template_id, memo_id } = props;

    /**
     * Connect to Redux store
     */
    const { memo, template, sequences, _template_load_state } = useSelector((state: RootState) => state.MemoFormV2);
    const { user } = useSelector((state: RootState) => state.Auth);
    const initialModal = {
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: /* istanbul ignore next */ () => {
                    return;
                },
            },
        ],
    };
    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading...');

    /**
     * For future scalability concerns,
     * it is best if the child cell component handles the file uploading.
     *
     * There can be an arbitrary number of file upload components to be created and
     * it will not be scalable to that this base form component should handle the
     * the state management for it
     *
     * For now, there can be 1 allowed file uploads per memo.
     */
    const [files, setFiles] = React.useState<TempFile[]>([]);
    const [fileUploadLocation, setFileUploadLocation] = React.useState<{
        sectionIndex: number | null;
        itemIndex: number | null;
    }>({
        sectionIndex: null,
        itemIndex: null,
    });
    const [remarksState, setRemarks] = React.useState('');
    const remarksRef = React.useRef(remarksState);

    /**
     * A dispatch function that will dispatch actions to reducers.
     */
    const dispatch: AppDispatch = useDispatch();

    /**
     * Third-party form validation library.
     */
    const { handleSubmit, register, errors, clearErrors, control } = useForm({ mode: 'onChange' });

    /**
     * Handler for submission.
     * Responsible hitting the POST request to create/edit memo endpoint.
     */
    const onSubmit = async () => {
        try {
            let res = null;

            const showSuccessModal = (res: { status_code: string }) => {
                /* istanbul ignore else */
                if (res && (res as { status_code: string }).status_code === 'success') {
                    setModalContent({
                        isVisible: true,
                        loading: false,
                        icon: ic_check,
                        title: 'Your memo has been submitted',
                        subtitle: 'A notification will be sent to the assigned recipients.',
                        buttons: [
                            {
                                label: 'Okay, got it!',
                                event: () => {
                                    setModalContent(initialModal);
                                    navigate('/memos');
                                },
                            },
                        ],
                    });
                }
            };

            if (mode === 'EDIT' && memo.memo_status !== 'SAVE_AS_DRAFT') {
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: false,
                    title: 'Resubmit memo',
                    subtitle: `Please provide remarks below.`,
                    extras: 'resubmit_memo',
                    extraComponent: (
                        <TextArea
                            id="txt-reason"
                            label="Remarks"
                            rows={4}
                            placeholder="Type your remarks here."
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setRemarks(e.currentTarget.value);
                                remarksRef.current = e.currentTarget.value;
                            }}
                            data-testid="textarea-remarks"
                        />
                    ),
                    buttons: [
                        {
                            label: 'Close',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                setRemarks('');
                                remarksRef.current = '';
                            },
                        },
                        {
                            label: 'Proceed',
                            event: async () => {
                                // Show loading output
                                setModalContent({
                                    isVisible: true,
                                    loading: true,
                                    title: '',
                                    subtitle: '',
                                    buttons: [],
                                });
                                res = await saveMemo(mode as string, 'SUBMITTED');
                                showSuccessModal(res);
                            },
                        },
                    ],
                });
            } else {
                // Show loading output
                setModalContent({
                    isVisible: true,
                    loading: true,
                    title: '',
                    subtitle: '',
                    buttons: [],
                });
                res = await saveMemo(mode as string, 'SUBMITTED');
                showSuccessModal(res);
            }
        } catch (error: any) {
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: 'There was an error while sending the memo.',
                subtitle: error.message,
                buttons: [
                    {
                        label: 'Okay, got it!',
                        event: () => {
                            setModalContent(initialModal);
                        },
                    },
                ],
            });
        }
    };

    /**
     * Handler for submitting draft.
     */
    const onSubmitDraft = async () => {
        try {
            // Show loading output
            setModalContent({
                isVisible: true,
                loading: true,
                title: '',
                subtitle: '',
                buttons: [],
            });
            const res = await saveMemo(mode as string, 'SAVE_AS_DRAFT');

            /* istanbul ignore else */
            if (res.status_code === 'success') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_check,
                    title: 'Your memo has been saved as draft',
                    subtitle: 'You may edit and publish your draft later.',
                    buttons: [
                        {
                            label: 'Okay, got it!',
                            event: () => {
                                setModalContent(initialModal);
                                navigate('/memos');
                            },
                        },
                    ],
                });
            }
        } catch (error: any) {
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: 'There was an error while sending the memo.',
                subtitle: error.message,
                buttons: [
                    {
                        label: 'Okay, got it!',
                        event: () => {
                            setModalContent(initialModal);
                        },
                    },
                ],
            });
        }
    };

    const onError = (error: any) => {
        console.log(error);
    };

    const saveMemo = async (mode: string, action: string) => {
        try {
            let uploadedFiles: TempFile[] = files;

            // If there are any files to upload
            const filesWithoutURls = files.filter((file: TempFile) => !file.url);
            if (filesWithoutURls && filesWithoutURls.length) {
                // upload them
                try {
                    setLoadingMessage('Please wait while your files are being uploaded.');
                    // Upload first the selected files
                    const newFiles = await uploadFiles(filesWithoutURls);
                    uploadedFiles = files.reduce((arr: TempFile[], curr: TempFile) => {
                        const newFile = newFiles.find((file: TempFile) => file.name === curr.name);
                        if (newFile) {
                            arr.push({
                                ...curr,
                                url: newFile.url,
                                name: newFile.name,
                            });
                        } else {
                            arr.push(curr);
                        }
                        return arr;
                    }, []);
                } catch (uploadErrors) {
                    throw uploadErrors;
                }
            }

            // Update the file upload field values
            if (template.api_version === 'v2') {
                dispatch(
                    updateMemo({
                        path: `memo_content.${fileUploadLocation.sectionIndex}.value.${fileUploadLocation.itemIndex}.value`,
                        data: uploadedFiles,
                    }),
                );
            } else {
                dispatch(
                    updateMemo({
                        path: `memo_content.${fileUploadLocation.itemIndex}.value`,
                        data: uploadedFiles,
                    }),
                );
            }
            dispatch(
                updateMemo({
                    path: 'attachment_urls',
                    data: uploadedFiles,
                }),
            );
            setLoadingMessage('Saving your memo...');

            const res = await dispatch(
                submitMemo({
                    ...Store.getState().MemoFormV2.memo,
                    api_version: Store.getState().MemoFormV2.template.api_version,
                    used_template_id: Store.getState().MemoFormV2.template.template_id,
                    used_template_name: Store.getState().MemoFormV2.template.template_name,
                    sequences: Store.getState().MemoFormV2.sequences,
                    _mode: mode || '',
                    action: action,
                    user_id: user.uid,
                    remarks: remarksRef.current,
                }),
            );

            return unwrapResult(res);
        } catch (error) {
            throw error;
        }
    };

    const onChange = (e: Record<string, any>, memoContent: MemoContent, index: number, sectionIndex?: number): void => {
        if (!e.currentTarget) return;

        const value = getFieldValue(e as React.ChangeEvent, memoContent);
        if (e.currentTarget.id === 'TEXT_SUBJECT') {
            dispatch(updateMemo({ path: `memo_subject`, data: value }));
        }

        if (template.api_version === 'v2' || memo.api_version === 'v2') {
            const path = `memo_content.${sectionIndex}.value.${index}.value`;
            updateField(path, value);
        } else {
            const path = `memo_content.${index}.value`;
            updateField(path, value);
        }
    };

    const getFieldValue = (event: React.ChangeEvent<any>, memoContent: MemoContent) => {
        const value = event.currentTarget.value || event.target.value;
        if (
            template.api_version &&
            template.api_version === 'v2' &&
            (event as React.ChangeEvent<HTMLInputElement>).currentTarget.type === 'checkbox'
        ) {
            const checkboxEvent = event as React.ChangeEvent<HTMLInputElement>;
            const options = _.clone(memoContent.value);
            const valueIndex = _.findIndex(options, (option) => option === checkboxEvent.currentTarget.value);

            if (checkboxEvent.currentTarget.checked) {
                if (valueIndex !== -1) {
                    _.set(options, valueIndex, value);
                    return options;
                } else {
                    return [...options, value];
                }
            } else {
                return options.filter((option: string) => option !== value);
            }
        } else {
            return value;
        }
    };

    const updateField = (path: string, value: any) => {
        dispatch(updateMemo({ path: path, data: value }));
    };

    /**
     * Generates a list of form field items from Redux store to be rendered back to DOM.
     * @param memo_contents
     * @returns
     */
    const generateDOMList = (memo_contents: MemoContent[]) => {
        return _.map(memo_contents, (memo_content, index) => {
            const {
                field_id,
                field_name,
                field_type,
                constant,
                placeholder,
                validations,
                annotation,
                value,
                sectionIndex,
            } = memo_content;

            switch (field_type) {
                case 'TEXT':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col py-1 px-4" id={`cell-content-${field_id}`}>
                                <Input
                                    type="text"
                                    label={field_name}
                                    id={field_id}
                                    placeholder={placeholder}
                                    onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                    ref={register({
                                        required: {
                                            value: validations.required as boolean,
                                            message: 'This field is required',
                                        },
                                    })}
                                    validations={validations}
                                    errors={errors}
                                    maxLength={validations.max_length}
                                    defaultValue={value}
                                    annotation={annotation}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                    aria-label={field_id}
                                />
                                {validations.max_length && validations.max_length > 0 && (
                                    <p className="text-xs font-regular ml-auto">
                                        {value?.length ? value.length : 0}/{validations.max_length || 150} characters
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                case 'TEXT_CURRENCY':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col px-4" id={`cell-content-${field_id}`}>
                                <Input
                                    type="currency"
                                    label={field_name}
                                    id={field_id}
                                    placeholder={placeholder}
                                    onChange={(e) => {
                                        onChange(
                                            {
                                                ...e,
                                                currentTarget: {
                                                    ...e.currentTarget,
                                                    dataset: {
                                                        index: index,
                                                        sectionindex: sectionIndex,
                                                    },
                                                },
                                            },
                                            memo_content,
                                            index,
                                            sectionIndex,
                                        );
                                    }}
                                    control={control}
                                    ref={register({
                                        required: {
                                            value: validations.required as boolean,
                                            message: 'This field is required.',
                                        },
                                    })}
                                    validations={validations}
                                    errors={errors}
                                    maxLength={validations.max_length}
                                    defaultValue={value || 0}
                                    annotation={annotation}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                    aria-label={field_id}
                                    data-testid={field_id}
                                />
                            </div>
                        </div>
                    );
                case 'TEXT_RTE':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto px-4" id={`cell-content-${field_id}`}>
                                <Editor
                                    id={field_id}
                                    label={field_name}
                                    ref={register({ required: validations.required })}
                                    errors={errors}
                                    validations={validations}
                                    defaultValue={value}
                                    onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                    index={index}
                                    sectionIndex={sectionIndex}
                                />
                            </div>
                        </div>
                    );
                case 'TEXT_RTE_PREFILLED':
                    return (
                        <div className={`w-full h-auto mb-4`} key={field_id}>
                            <div className="w-full h-auto px-4" id={`cell-content-${field_id}`}>
                                <Editor
                                    id={field_id}
                                    label={field_name}
                                    ref={register({ required: validations.required })}
                                    errors={errors}
                                    validations={validations}
                                    defaultValue={value}
                                    onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                    index={index}
                                    sectionIndex={sectionIndex}
                                />
                            </div>
                        </div>
                    );
                case 'DROPDOWN':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto px-4" id={`cell-content-${field_id}`}>
                                <Select
                                    id={field_id}
                                    ref={register({
                                        required: {
                                            value: validations.required as boolean,
                                            message: 'This field is required.',
                                        },
                                    })}
                                    label={field_name}
                                    onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                    validations={validations}
                                    errors={errors}
                                    defaultValue={value ? value : ''}
                                    annotation={annotation}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                    data-testid={field_id}
                                >
                                    {value}
                                    <option value="" disabled selected hidden>
                                        {placeholder}
                                    </option>
                                    {constant &&
                                        (constant as Array<any>).map((item: SelectItems) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                </Select>
                                {(() => {
                                    const selectedValueIndex = _.findIndex(
                                        constant,
                                        (constant: TemplateConstant) => constant.value === value,
                                    );
                                    const selectedValue = constant[selectedValueIndex];
                                    if (
                                        selectedValueIndex !== -1 &&
                                        selectedValue.branchingQuestions &&
                                        selectedValue.branchingQuestions.length
                                    ) {
                                        return (
                                            <div className="w-full bg-blue-light h-auto pt-4 pb-6 mt-4">
                                                {_.map(
                                                    selectedValue.branchingQuestions,
                                                    (question, questionIndex: number) => {
                                                        const {
                                                            field_id,
                                                            field_name,
                                                            field_type,
                                                            constant,
                                                            placeholder,
                                                            validations,
                                                            annotation,
                                                            value,
                                                            sectionIndex,
                                                        } = question;

                                                        const onBranchingQuestionChange = (
                                                            e: React.ChangeEvent<any>,
                                                        ) => {
                                                            const value = e.currentTarget.value || e.target.value;
                                                            const data = _.cloneDeep(
                                                                _.get(
                                                                    memo,
                                                                    `memo_content.${sectionIndex}.value.${index}`,
                                                                ),
                                                            );
                                                            _.set(
                                                                data,
                                                                `constant.${selectedValueIndex}.branchingQuestions.${questionIndex}.value`,
                                                                value,
                                                            );
                                                            updateField(
                                                                `memo_content.${sectionIndex}.value.${index}`,
                                                                data,
                                                            );
                                                        };

                                                        switch (field_type) {
                                                            case 'TEXT':
                                                                return (
                                                                    <div
                                                                        className={`w-full h-auto ${
                                                                            template.api_version === 'v2'
                                                                                ? 'mb-0'
                                                                                : 'mb-4'
                                                                        }`}
                                                                        key={field_id}
                                                                    >
                                                                        <div
                                                                            className="w-full h-auto flex flex-col py-1 px-4"
                                                                            id={`cell-content-${field_id}`}
                                                                        >
                                                                            <Input
                                                                                type="text"
                                                                                label={field_name}
                                                                                id={field_id}
                                                                                placeholder={placeholder}
                                                                                onChange={onBranchingQuestionChange}
                                                                                ref={register({
                                                                                    required: {
                                                                                        value: validations.required as boolean,
                                                                                        message:
                                                                                            'This field is required',
                                                                                    },
                                                                                })}
                                                                                validations={validations}
                                                                                errors={errors}
                                                                                maxLength={validations.max_length}
                                                                                defaultValue={value}
                                                                                annotation={annotation}
                                                                                data-index={index}
                                                                                data-sectionIndex={sectionIndex}
                                                                                aria-label={field_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            case 'TEXT_CURRENCY':
                                                                return (
                                                                    <div
                                                                        className={`w-full h-auto ${
                                                                            template.api_version === 'v2'
                                                                                ? 'mb-0'
                                                                                : 'mb-4'
                                                                        }`}
                                                                        key={field_id}
                                                                    >
                                                                        <div
                                                                            className="w-full h-auto flex flex-col px-4"
                                                                            id={`cell-content-${field_id}`}
                                                                        >
                                                                            <Input
                                                                                type="currency"
                                                                                label={field_name}
                                                                                id={field_id}
                                                                                placeholder={placeholder}
                                                                                onChange={(e) => {
                                                                                    onBranchingQuestionChange({
                                                                                        ...e,
                                                                                        currentTarget: {
                                                                                            ...e.currentTarget,
                                                                                            dataset: {
                                                                                                index: index,
                                                                                                sectionindex: sectionIndex,
                                                                                            },
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                control={control}
                                                                                ref={register({
                                                                                    required: {
                                                                                        value: validations.required as boolean,
                                                                                        message:
                                                                                            'This field is required.',
                                                                                    },
                                                                                })}
                                                                                validations={validations}
                                                                                errors={errors}
                                                                                maxLength={validations.max_length}
                                                                                defaultValue={value || 0}
                                                                                annotation={annotation}
                                                                                data-index={index}
                                                                                data-sectionIndex={sectionIndex}
                                                                                aria-label={field_id}
                                                                                data-testid={field_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            case 'DROPDOWN':
                                                                return (
                                                                    <div
                                                                        className={`w-full h-auto ${
                                                                            template.api_version === 'v2'
                                                                                ? 'mb-0'
                                                                                : 'mb-4'
                                                                        }`}
                                                                        key={field_id}
                                                                    >
                                                                        <div
                                                                            className="w-full h-auto px-4"
                                                                            id={`cell-content-${field_id}`}
                                                                        >
                                                                            <Select
                                                                                id={field_id}
                                                                                ref={register({
                                                                                    required: {
                                                                                        value: validations.required as boolean,
                                                                                        message:
                                                                                            'This field is required.',
                                                                                    },
                                                                                })}
                                                                                label={field_name}
                                                                                onChange={(event) =>
                                                                                    onBranchingQuestionChange(event)
                                                                                }
                                                                                validations={validations}
                                                                                errors={errors}
                                                                                defaultValue={value ? value : ''}
                                                                                annotation={annotation}
                                                                                data-index={index}
                                                                                data-sectionIndex={sectionIndex}
                                                                                data-testid={field_id}
                                                                            >
                                                                                {value}
                                                                                <option
                                                                                    value=""
                                                                                    disabled
                                                                                    selected
                                                                                    hidden
                                                                                >
                                                                                    {placeholder}
                                                                                </option>
                                                                                {constant &&
                                                                                    (constant as Array<any>).map(
                                                                                        (item: SelectItems) => (
                                                                                            <option
                                                                                                key={item.value}
                                                                                                value={item.value}
                                                                                            >
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ),
                                                                                    )}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                );
                                                        }
                                                    },
                                                )}
                                            </div>
                                        );
                                    }
                                })()}
                            </div>
                        </div>
                    );
                case 'DROPDOWN_DEFAULT':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto px-4" id={`cell-content-${field_id}`}>
                                <Select
                                    id={field_id}
                                    ref={register({
                                        required: {
                                            value: validations.required as boolean,
                                            message: 'This field is required.',
                                        },
                                    })}
                                    label={field_name}
                                    onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                    validations={validations}
                                    errors={errors}
                                    defaultValue={value ? value : ''}
                                    annotation={annotation}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                >
                                    {value}
                                    {constant &&
                                        (constant as Array<any>).map((item: SelectItems) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    );
                case 'DATEPICKER':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col px-4 mt-1" id={`cell-content-${field_id}`}>
                                <Label htmlFor={field_id}>
                                    {field_name} {validations?.required ? <span className="text-red">*</span> : ''}
                                </Label>
                                {annotation && (
                                    <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                        {annotation}
                                    </p>
                                )}
                                <Datepicker
                                    type="singleDate"
                                    id={field_id}
                                    label="Select date"
                                    onSingleChange={(e) => {
                                        onChange(
                                            {
                                                currentTarget: {
                                                    ...e.currentTarget,
                                                    dataset: {
                                                        index: index,
                                                        sectionindex: sectionIndex,
                                                    },
                                                },
                                            },
                                            memo_content,
                                            index,
                                            sectionIndex,
                                        );
                                    }}
                                    value={value}
                                    errors={errors}
                                    control={control}
                                    validations={validations}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                />
                            </div>
                        </div>
                    );
                case 'TIMEPICKER':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col px-4" id={`cell-content-${field_id}`}>
                                <Label htmlFor={field_id}>
                                    {field_name} {validations?.required ? <span className="text-red">*</span> : ''}
                                </Label>
                                <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">{annotation}</p>
                                <Datepicker
                                    type="timeOnly"
                                    id={field_id}
                                    label="Select time"
                                    onSingleChange={(e) => {
                                        onChange(
                                            {
                                                currentTarget: {
                                                    ...e.currentTarget,
                                                    dataset: {
                                                        index: index,
                                                        sectionindex: sectionIndex,
                                                    },
                                                },
                                            },
                                            memo_content,
                                            index,
                                            sectionIndex,
                                        );
                                    }}
                                    defaultValue={value}
                                    control={control}
                                    value={value}
                                    errors={errors}
                                    validations={validations}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                />
                            </div>
                        </div>
                    );
                case 'RADIO':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col px-4" id={`cell-content-${field_id}`}>
                                <Label htmlFor={field_id}>
                                    {field_name} {validations?.required ? <span className="text-red">*</span> : ''}
                                </Label>
                                {annotation && (
                                    <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                        {annotation}
                                    </p>
                                )}

                                {_.map(constant, (option: any, i: number) => (
                                    <div className="w-auto h-auto block">
                                        <Input
                                            id={`option-${field_id}-${i}`}
                                            label={option.label}
                                            name={field_id}
                                            type="radio"
                                            value={option.value}
                                            defaultChecked={option.value === value}
                                            onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                            ref={register({
                                                required: {
                                                    value: validations.required as boolean,
                                                    message: 'This field is required',
                                                },
                                            })}
                                            errors={errors}
                                            data-index={index}
                                            data-sectionIndex={sectionIndex}
                                            data-testid={`option-${field_id}-${i}`}
                                        />
                                    </div>
                                ))}
                                {errors ? (
                                    errors[field_id] ? (
                                        <p className="text-xs font-regular text-red">{errors[field_id].message}</p>
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    );
                case 'CHECKBOX':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto flex flex-col px-4" id={`cell-content-${field_id}`}>
                                <Label htmlFor={field_id}>
                                    {field_name} {validations?.required ? <span className="text-red">*</span> : ''}
                                </Label>
                                {annotation && (
                                    <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                        {annotation}
                                    </p>
                                )}
                                {_.map(constant, (option: any, i: number) => (
                                    <div className="w-auto h-auto block">
                                        <Input
                                            id={`option-${field_id}-${i}`}
                                            label={option.label}
                                            name={field_id}
                                            type="checkbox"
                                            value={option.value}
                                            defaultChecked={value.includes(option.value)}
                                            onChange={(event) => onChange(event, memo_content, index, sectionIndex)}
                                            ref={register({
                                                required: {
                                                    value: validations.required as boolean,
                                                    message: 'This field is required',
                                                },
                                            })}
                                            errors={errors}
                                            data-index={index}
                                            data-sectionIndex={sectionIndex}
                                            data-testid={`option-${field_id}-${i}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'FILE_UPLOAD':
                    return (
                        <div
                            className={`w-full h-auto ${template.api_version === 'v2' ? 'mb-0' : 'mb-4'}`}
                            key={field_id}
                        >
                            <div className="w-full h-auto px-4 pb-1" id={`cell-content-${field_id}`}>
                                <FileUpload
                                    register={register}
                                    files={files}
                                    onChange={setFiles}
                                    validations={validations}
                                    annotation={annotation}
                                    data-index={index}
                                    data-sectionIndex={sectionIndex}
                                    label={field_name}
                                />
                            </div>
                        </div>
                    );
                default:
                    return <Input type="text" label={field_name} id={field_id} errors={errors} />;
            }
        });
    };

    /**
     * Generates memo form fields to the Redux store.
     * Backwards compatibility for non-sectioned templates.
     * @param template
     */
    const generateV1Fields = async (template: Template) => {
        let memo_content: TemplateContent[] = [];
        if (mode === 'CREATE') {
            memo_content = _.map(template.template_content, (content, itemIndex) => {
                if (content.field_type === 'TEXT_RTE' || content.field_type === 'TEXT_RTE_PREFILLED') {
                    return {
                        ...content,
                        value: content.constant,
                    };
                }
                if (content.field_type === 'DROPDOWN_DEFAULT') {
                    return {
                        ...content,
                        value: content.constant ? (content.constant as Array<Record<string, any>>)[0].value : '',
                    };
                } else if (content.defaultValue) {
                    return {
                        ...content,
                        value: content.defaultValue,
                    };
                } else if (content.field_type === 'FILE_UPLOAD') {
                    setFileUploadLocation({
                        sectionIndex: null,
                        itemIndex: itemIndex,
                    });
                    return {
                        ...content,
                        value: '',
                    };
                }
                return {
                    ...content,
                    value: '',
                };
            });
            dispatch(
                updateMemo({
                    path: ['template_content', 'memo_content', 'used_template_id'],
                    data: [template.template_content, memo_content, template.template_id],
                }),
            );
        } else if (mode === 'EDIT') {
            memo_content = _.map(memo.memo_content, (field_content, itemIndex) => {
                const content = {
                    ...template.template_content[itemIndex],
                    ...field_content,
                };
                if (content.field_type === 'FILE_UPLOAD') {
                    setFileUploadLocation({
                        sectionIndex: null,
                        itemIndex: itemIndex,
                    });
                    setFiles(memo.attachment_urls);
                    return {
                        ...content,
                        value: memo.attachment_urls, // memos prior to v2 or pre-v2 stores file and its url on attachment_urls
                    };
                }
                return {
                    ...content,
                    value: content.value,
                };
            });
            dispatch(
                updateMemo({
                    path: [
                        'memo_id',
                        'memo_subject',
                        'used_template_id',
                        'memo_status',
                        'date_created',
                        'date_submitted',
                        'date_last_modified',
                        'attachment_urls',
                        'memo_content',
                        'template_content',
                        'current_sequence',
                        'approvers',
                        'watchers',
                    ],
                    data: [
                        memo.memo_id,
                        memo.memo_subject,
                        template.template_id,
                        memo.memo_status,
                        memo.date_created,
                        memo.date_submitted,
                        memo.date_last_modified,
                        memo.attachment_urls,
                        memo_content,
                        template.template_content,
                        memo.current_sequence,
                        memo.approvers,
                        memo.watchers,
                    ],
                }),
            );
        }
    };

    /**
     * To persist form field ordering in the DOM, we need to sort the cells based on their Y values.
     * This function takes the DOM elements and returns an array of objects with the element sorted by its Y value.
     */
    const sortFormFields = (content: any, _metadata: any) => {
        const metadata = _.map(_metadata, (m) => ({ field_id: m.i, y: m.y }));
        const values = _.values(_.merge(_.keyBy(metadata, 'field_id'), _.keyBy(content, 'field_id')));
        const sorted = _.sortBy(values, 'y');
        return _.map(sorted, (s) => {
            return _.omit(s, 'y');
        });
    };

    /**
     * TODO: Create an algorithm that generates the form fields in Redux store.
     * Generates memo form fields to Redux store.
     * Supports grid-based layout
     */
    const generateFields = () => {
        let memo_content: TemplateContent[] = [];
        if (mode === 'CREATE') {
            memo_content = _.map(template.template_content, (sections, sectionIndex) => {
                const values = sortFormFields(sections.value, sections._metadata);
                return {
                    ...sections,
                    value: _.map(values, (field, itemIndex) => {
                        if (field.field_type === 'TEXT_RTE' || field.field_type === 'TEXT_RTE_PREFILLED') {
                            return {
                                ...field,
                                value: field.constant,
                            };
                        } else if (field.defaultValue) {
                            return {
                                ...field,
                                value: field.defaultValue,
                            };
                        } else if (field.field_type === 'FILE_UPLOAD') {
                            setFileUploadLocation({
                                sectionIndex: sectionIndex,
                                itemIndex: Number(itemIndex),
                            });
                            return {
                                ...field,
                                value: '',
                            };
                        }
                        return {
                            ...field,
                            value: '',
                        };
                    }),
                };
            });
            dispatch(
                updateMemo({
                    path: ['template_content', 'memo_content', 'used_template_id'],
                    data: [template.template_content, memo_content, template.template_id],
                }),
            );
        } else if (mode === 'EDIT') {
            memo_content = _.map(memo.memo_content, (sections, sectionIndex) => {
                const values = sortFormFields(sections.value, sections._metadata);
                return {
                    ...sections,
                    value: _.map(values, (field, itemIndex) => {
                        if (field.field_type === 'TEXT_RTE' || field.field_type === 'TEXT_RTE_PREFILLED') {
                            return {
                                ...field,
                                value: field.value,
                            };
                        } else if (field.defaultValue) {
                            return {
                                ...field,
                                value: field.value,
                            };
                        } else if (field.field_type === 'FILE_UPLOAD') {
                            setFileUploadLocation({
                                sectionIndex: sectionIndex,
                                itemIndex: Number(itemIndex),
                            });
                            setFiles(memo.attachment_urls);
                            return {
                                ...field,
                                value: memo.attachment_urls,
                            };
                        }
                        return {
                            ...field,
                            value: field.value,
                        };
                    }),
                };
            });
            dispatch(
                updateMemo({
                    path: [
                        'memo_id',
                        'memo_subject',
                        'used_template_id',
                        'memo_status',
                        'date_created',
                        'date_submitted',
                        'date_last_modified',
                        'attachment_urls',
                        'memo_content',
                        'template_content',
                        'current_sequence',
                        'approvers',
                        'watchers',
                    ],
                    data: [
                        memo.memo_id,
                        memo.memo_subject,
                        template.template_id,
                        memo.memo_status,
                        memo.date_created,
                        memo.date_submitted,
                        memo.date_last_modified,
                        memo.attachment_urls,
                        memo_content,
                        template.template_content,
                        memo.current_sequence,
                        memo.approvers,
                        memo.watchers,
                    ],
                }),
            );
        }
    };

    /**
     * Wrapper function for generating either a v1 or the section-based layouts.
     * @param template
     */
    const buildForm = (template: Template) => {
        if (template.api_version && template.api_version === 'v2') {
            generateFields();
        } else {
            console.log('generating legacy fields');
            // legacy
            generateV1Fields(template);
        }

        dispatch(
            updateMemo({
                path: ['memo_owner_id', 'memo_owner_email'],
                data: [user.uid, user.email],
            }),
        );
        dispatch(setTemplateLoadState('FULFILLED'));
    };

    /**
     * HTTP Request for getting a template based on template id.
     * @param id - The template ID.
     * @returns
     */
    const getTemplate = async (id?: string) => {
        if (!id) {
            navigate('/memo/create');
            return;
        }

        try {
            const getTemplateFn = firebase.app().functions('asia-east2').httpsCallable('get_template_by_id');
            const httpResponse = await getTemplateFn({ template_id: id });
            return httpResponse.data;
        } catch (err) {
            throw err;
        }
    };

    /**
     * HTTP Request for getting a memo based on memo id.
     */
    const getMemo = async (id?: string) => {
        if (!id) {
            navigate('/memo/create');
            return;
        }

        try {
            const getMemoFn = firebase.app().functions('asia-east2').httpsCallable('getMemoDetailsForEditing');
            const httpResponse = await getMemoFn({ memo_id: id });
            return httpResponse.data;
        } catch (err) {
            throw err;
        }
    };

    /**
     * Checks if integration to Google APIs are valid.
     * @param idToken
     */
    const getIntegrationToken = async (idToken?: string) => {
        try {
            const tokenUri =
                window.location.hostname === 'localhost'
                    ? `http://localhost:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/auth/auth/token`
                    : `${window.location.origin}/auth/token`;
            await axios.post(
                tokenUri,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                },
            );
        } catch (err) {
            if ((err as any).response && (err as any).response.status === 403) {
                throw new Error('INTEGRATION_UNSUPPORTED');
            }
            throw new Error('INTEGRATION_FAILED');
        }
    };

    /**
     * Runs the bootstrap function onLoad.
     */
    React.useEffect(() => {
        let isMounted = true;

        /**
         * Bootstraps the component.
         */
        const init = async () => {
            // Update the UI and set it to a loading state.
            dispatch(setTemplateLoadState('PENDING'));
            dispatch(setMode(mode as string));

            // Checks if a user is currently signed in, and
            // if the app has the permission to view their contacts.
            try {
                // Get user account token
                const userToken = await firebase.auth().currentUser?.getIdToken();

                // Send the request to backend
                await getIntegrationToken(userToken);
            } catch (err) {
                if ((err as any).message === 'INTEGRATION_UNSUPPORTED') {
                    // If the user's provider type is unsupported, view a custom message
                    dispatch(setTemplateLoadState('INTEGRATION_UNSUPPORTED'));
                    return;
                } else {
                    // If the Google API integration failed,
                    // log the error and update the UI.
                    dispatch(setTemplateLoadState('INTEGRATION_FAILED'));
                    // throw { message: 'INTEGRATION_FAILED' };
                    return;
                }
            }

            // Get the template from the server
            try {
                let defaultTemplate: Template;
                let defaultSequences: Sequence[] = [];
                let defaultMemo: Memo;

                // Get the template,
                if (mode === 'CREATE') {
                    const template = await getTemplate(template_id);
                    defaultTemplate = {
                        ...template,
                    };

                    // Create an empty initial sequence.
                    defaultSequences = [
                        {
                            sequence_no: 0,
                            sequence_id: nanoid(),
                            approvers: [],
                            approverSearchToken: '',
                            watchers: [],
                            watcherSearchToken: '',
                            approval_type: 'ALL_APPROVERS',
                            is_cleared: false,
                            allow_sub_approvals: false,
                        },
                    ];

                    if (isMounted) {
                        dispatch(setTemplate(defaultTemplate));

                        // Update the store with the initial sequence
                        dispatch(setSequences([...sequences, ...defaultSequences]));

                        // Update the store.
                        dispatch(setTemplateLoadState('GET_TEMPLATE_FULFILLED'));
                    }
                } else if (mode === 'EDIT') {
                    // replace with http request
                    // const memoData = v1;
                    const data = await getMemo(memo_id);
                    const template = await getTemplate(data.memo.used_template_id);

                    defaultMemo = data.memo;
                    defaultTemplate = {
                        template_content: _.map(defaultMemo.template_content, (content) => {
                            return {
                                ...content,
                                sectionIndex: content.sectionIndex || 0,
                            };
                        }),
                        template_id: defaultMemo.used_template_id,
                        template_name: defaultMemo.used_template_name as string,
                        creator_user_id: '',
                        status: '',
                        date_created: '',
                        date_last_modified: '',
                        guidelines: template.guidelines,
                        api_version: (defaultMemo as any).api_version,
                    };

                    defaultSequences = _.map(data.memo.raw_sequences, (seq) => {
                        return {
                            ...seq,
                            approvers: _.map(seq.approvers, (app) => ({ ...app, searchToken: '' })),
                            watchers: _.map(seq.watchers, (watc) => ({ ...watc, searchToken: '' })),
                        };
                    });

                    if (isMounted) {
                        // Update the store with the initial sequence
                        dispatch(setSequences([...sequences, ...defaultSequences]));

                        // Update the store.
                        dispatch(updateMemo({ path: '*', data: defaultMemo }));
                        dispatch(setTemplate(defaultTemplate));
                        dispatch(setTemplateLoadState('GET_TEMPLATE_FULFILLED'));
                    }
                }

                const page_title = `${Store.getState().MemoFormV2.template.template_name} | Create a Memo`;
                document.title = page_title;
                firebase.analytics().setCurrentScreen(location.pathname);
                firebase.analytics().logEvent('page_view', {
                    page_path: location.pathname,
                    page_title: page_title,
                });
            } catch (err) {
                console.log(err);
                dispatch(setTemplateLoadState('GET_TEMPLATE_FAILED'));
                // throw { message: 'GET_TEMPLATE_FAILED' };
                return;
            }
        };
        init();
        return () => {
            dispatch(clearForm());
            isMounted = false;
        };
    }, []);

    /**
     * Runs the form generators after successful template load.
     */
    React.useEffect(() => {
        if (_template_load_state === 'GET_TEMPLATE_FULFILLED') {
            setTimeout(() => {
                buildForm(template);
            }, 1500);
        }
    }, [template, _template_load_state]);

    switch (_template_load_state) {
        case 'PENDING':
            return (
                <Container>
                    <div className="w-full">
                        <Preloader type="dots" text="Loading your memo..." />
                    </div>
                </Container>
            );
        case 'GET_TEMPLATE_FULFILLED':
            return (
                <Container>
                    <div className="w-full">
                        <Preloader type="dots" text="Arranging the form..." />
                    </div>
                </Container>
            );
        case 'INTEGRATION_UNSUPPORTED':
            return (
                <div className="bg-white w-full h-full absolute px-12 top-0 left-0 flex items-center justify-center opacity-95">
                    <div className="w-10/11 h-auto p-2 flex flex-col items-center">
                        <img src={ic_cog} width={100} alt="alert" />
                        <p className="text-md font-bold my-1">More features coming soon!</p>
                        <p className="text-sm font-regular text-gray-2 text-center my-1">
                            We’re continuously building new features to improve your Memo.ph experience
                        </p>
                    </div>
                </div>
            );
        case 'INTEGRATION_FAILED':
            return (
                <div className="bg-white w-full h-full absolute px-12 top-0 left-0 flex items-center justify-center opacity-95">
                    <div className="w-10/11 h-auto p-2 flex flex-col items-center">
                        <img src={ic_alert} width={100} alt="alert" />
                        <p className="text-md font-bold my-1">Allow your contacts.</p>
                        <p className="text-sm font-regular text-gray-2 text-center my-1">
                            We need your permission to access your <strong>Google Contacts</strong> so you can add your
                            memo audiences.
                        </p>
                        <div className="w-full flex flex-row items-center justify-center my-4">
                            <div className="w-auto h-auto mx-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                        const redirectURI =
                                            window.location.hostname === 'localhost'
                                                ? `${window.location.protocol}//${window.location.hostname}:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/auth/auth/google`
                                                : `${window.location.origin}/auth/google`;
                                        window.location.replace(redirectURI);
                                    }}
                                >
                                    Take me there.
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'FULFILLED':
            return (
                <Container>
                    <div className="w-full">
                        <Title>{template.template_name}</Title>
                        <div className="my-2">
                            <Subtitle>Please fill out the form below</Subtitle>
                        </div>
                        <div className="w-full flex flex-col-reverse md:flex-row mt-8">
                            <div className="w-full md:w-4/6 mb-8">
                                <form
                                    onSubmit={handleSubmit(onSubmit, onError)}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    {(() => {
                                        if (template.api_version === 'v2') {
                                            /**
                                             * Add conditional rendering if the section
                                             * should be visible or not
                                             */
                                            const sectionVisible = _.filter(memo.memo_content, (content) => {
                                                if (!content.conditions) return true;

                                                const conditions = content.conditions as TemplateCondition[];

                                                const truthTable = evalTruthList(conditions, (condition) => {
                                                    const referencedValue = _.get(
                                                        memo.memo_content,
                                                        condition.field_ref,
                                                    );

                                                    switch (condition.operator) {
                                                        case 'EQUALS':
                                                            return referencedValue === condition.value;
                                                        default:
                                                            return false;
                                                    }
                                                });

                                                return truthTable.includes(true);
                                            });

                                            /**
                                             * Render the filtered sections
                                             */
                                            return _.map(sectionVisible, (content) => (
                                                <div className="w-full bg-white p-8 mb-4" hidden={content.hidden}>
                                                    <div className="w-full mb-4">
                                                        <Title type="h4">{content.field_name}</Title>
                                                    </div>
                                                    {_template_load_state === 'FULFILLED' && (
                                                        <StaticGrid
                                                            margins={[0, 0]}
                                                            _metadata={content._metadata}
                                                            items={generateDOMList(content.value)}
                                                        />
                                                    )}
                                                </div>
                                            ));
                                        } else {
                                            return (
                                                <div className="w-full bg-white p-8 mb-4">
                                                    <div className="w-full mb-4">
                                                        <Title type="h4">Memo content</Title>
                                                    </div>
                                                    {generateDOMList(memo.memo_content)}
                                                </div>
                                            );
                                        }
                                    })()}
                                    <ApprovalWorkflow register={register} errors={errors} clearErrors={clearErrors} />
                                    <div className="w-full flex flex-col-reverse md:flex-row items-center my-8">
                                        <fieldset
                                            className="w-full flex flex-col-reverse md:flex-row px-4 md:px-0"
                                            disabled={modalContent.isVisible}
                                        >
                                            <Button
                                                type="button"
                                                weight="font-regular"
                                                onClick={() => {
                                                    navigate('/memos');
                                                }}
                                            >
                                                <u>Back</u>
                                            </Button>
                                            <div className="w-full flex flex-col md:w-auto md:flex-row">
                                                <Button
                                                    type="button"
                                                    id="draft"
                                                    color="secondary"
                                                    weight="font-regular"
                                                    disabled={modalContent.isVisible}
                                                    onClick={onSubmitDraft}
                                                >
                                                    Save as draft
                                                </Button>
                                            </div>
                                            <div className="w-full flex flex-col md:w-auto md:flex-row">
                                                <Button
                                                    color="primary"
                                                    id="submit"
                                                    weight="font-regular"
                                                    value="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                            {template.guidelines ? (
                                <div className="w-full md:w-1/4 lg:w-1/4 ml-0 md:ml-16 mb-8 mt-4 md:mt-0">
                                    <Guidelines guidelines={template.guidelines ? template.guidelines : ''} />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <Modal visible={modalContent.isVisible as boolean}>
                        {modalContent.loading ? (
                            <div className="w-full flex flex-col items-center justify-center">
                                <Preloader type="dots" text={loadingMessage} />
                            </div>
                        ) : (
                            <div
                                className={`w-full flex flex-col ${modalContent.icon ? `items-center` : `items-start`}`}
                                data-testid="modal-content"
                            >
                                {modalContent.icon ? (
                                    <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                                ) : (
                                    ''
                                )}
                                <Title type="h4">{modalContent.title}</Title>
                                <div className={`${modalContent.icon ? 'my-2' : 'my-0'}`}>
                                    <Text type="innerHTML">{modalContent.subtitle}</Text>
                                </div>
                                {modalContent.extraComponent ? (
                                    <div className="w-full mt-4">{modalContent.extraComponent}</div>
                                ) : (
                                    ''
                                )}
                                {modalContent.buttons.length > 1 ? (
                                    <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                weight="font-regular"
                                                onClick={modalContent.buttons[0].event}
                                            >
                                                {modalContent.buttons[0].label}
                                            </Button>
                                        </div>
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                color="primary"
                                                weight="font-regular"
                                                type="submit"
                                                disabled={
                                                    remarksState === '' &&
                                                    !['remind_solo_approver', 'remind_multi_approver'].includes(
                                                        modalContent.extras,
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                onClick={modalContent.buttons[1].event}
                                            >
                                                {modalContent.buttons[1].label}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="my-2">
                                        <Button
                                            type="button"
                                            color="primary"
                                            weight="font-regular"
                                            onClick={modalContent.buttons[0].event}
                                        >
                                            {modalContent.buttons[0].label}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Modal>
                </Container>
            );
        default:
            return null;
    }
};

export default MemoForm;
