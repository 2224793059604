import { navigate } from '@reach/router';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs, { BreadcrumbsItem } from '../../../layouts/breadcrumbs/Breadcrumbs';
import Container from '../../../layouts/container/Container';
import Preloader from '../../../layouts/preloaders/Preloader';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import { Memo } from '../../../models/Memo';
import { clearView, setMemo, setState } from '../../../redux/MemoViewSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import MemoViewActions from './MemoViewActions';
import MemoViewColumn from './MemoViewColumn';
import MemoViewContent from './MemoViewContent';
import MemoViewContentData from './MemoViewContentData';
import MemoViewContentGeneralInfo from './MemoViewContentGeneralInfo';
import MemoViewContentLogs from './MemoViewContentLogs';
import MemoViewHeader from './MemoViewHeader';

interface IMemoViewProps {
    memo_id?: string;
    path?: string;
    children?: React.ReactNode;
}

function MemoView(props: IMemoViewProps) {
    const { state, memo, memo_subject } = useSelector((rootState: RootState) => ({
        state: rootState.MemoView.state,
        memo: rootState.MemoView.memo,
        memo_subject: rootState.MemoView.memo.memo_subject,
    }));
    const [cfResponse, setCFResponse] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const [didRedirect, setRedirectStatus] = useState(false);

    React.useEffect(() => {
        let isMounted = true;
        if (!props.memo_id) return;

        const getMemo = async (memo_id: string) => {
            try {
                dispatch(setState('pending'));

                const getMemoByID = firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('get_memo_details_for_viewing');
                const response = await getMemoByID({ memo_id: memo_id });

                if (isMounted) {
                    dispatch(
                        setMemo({
                            ...response.data.memo,
                            raw_sequences: response.data.sequence,
                            approval_status: response.data.approval_status,
                            flags: response.data.flags,
                        } as Memo),
                    );

                    if (response.data.code === 200) {
                        dispatch(setState('fulfilled'));
                    } else {
                        dispatch(setState('rejected'));
                        if (response.data.code === 'permission-denied') {
                            setCFResponse('You do not have access to this memo.');
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                dispatch(setState('rejected'));
                setCFResponse(JSON.stringify(err));
            }
        };

        getMemo(props.memo_id);

        return () => {
            dispatch(clearView());
            isMounted = false;
        };
    }, [props.memo_id]);

    /**
     * TODO: Move this to the Breadcrumbs component lego.
     * @param title - Memo Title
     * @param path - current page path
     * @param memo_id - The memo ID
     */
    const getBreadCrumbsItems = (title: string, path?: string, memo_id?: string): BreadcrumbsItem[] => {
        switch (path) {
            case 'memos/:memo_id':
                return [
                    {
                        label: 'My memos',
                        link: '/memos',
                    },
                    {
                        label: title,
                        link: '/memos/' + memo_id,
                    },
                ];
            case 'memos/drafts/:memo_id':
                return [
                    {
                        label: 'My memos',
                        link: '/memos/',
                    },
                    {
                        label: 'Drafts',
                        link: '/memos/drafts',
                    },
                    {
                        label: title,
                        link: '/memos/drafts/' + memo_id,
                    },
                ];
            case 'memos/history/:memo_id':
                return [
                    {
                        label: 'My memos',
                        link: '/memos/active',
                    },
                    {
                        label: 'History',
                        link: '/memos/history',
                    },
                    {
                        label: title,
                        link: '/memos/history/' + memo_id,
                    },
                ];
            case 'approvals/:memo_id':
                return [
                    {
                        label: 'For my approval',
                        link: '/approvals',
                    },
                    {
                        label: title,
                        link: '/approvals/' + memo_id,
                    },
                ];
            case 'approvals/history/:memo_id':
                return [
                    {
                        label: 'For my approval',
                        link: '/approvals',
                    },
                    {
                        label: 'History',
                        link: '/approvals/history',
                    },
                    {
                        label: title,
                        link: '/approvals/history/' + memo_id,
                    },
                ];
            case 'watch/:memo_id':
                return [
                    {
                        label: 'Memos to watch',
                        link: '/watch',
                    },
                    {
                        label: title,
                        link: '/watch/' + memo_id,
                    },
                ];
            case 'watch/history/:memo_id':
                return [
                    {
                        label: 'Memos to watch',
                        link: '/watch',
                    },
                    {
                        label: 'History',
                        link: '/watch/history',
                    },
                    {
                        label: title,
                        link: '/watch/history/' + memo_id,
                    },
                ];
            default:
                return [
                    {
                        label: 'Memos',
                        link: '/memos',
                    },
                    {
                        label: title,
                        link: '/memos/' + memo_id,
                    },
                ];
        }
    };

    switch (state) {
        case 'pending':
            return (
                <Container>
                    <Preloader type="dots" />
                </Container>
            );
        case 'fulfilled':
            if (!didRedirect) {
                const { memo_status, audienceType } = memo;
                if (
                    ['APPROVED', 'RETURNED', 'REJECTED', 'SELF_RETURNED'].includes(memo_status) &&
                    audienceType === 'owner'
                ) {
                    setRedirectStatus(true);
                    navigate(`/memos/history/${props.memo_id}`);
                } else if (
                    ['APPROVED', 'RETURNED', 'REJECTED', 'SELF_RETURNED'].includes(memo_status) &&
                    audienceType === 'approver'
                ) {
                    setRedirectStatus(true);
                    navigate(`/approvals/history/${props.memo_id}`);
                } else if (
                    ['APPROVED', 'RETURNED', 'REJECTED', 'SELF_RETURNED'].includes(memo_status) &&
                    audienceType === 'watcher'
                ) {
                    setRedirectStatus(true);
                    navigate(`/watch/history/${props.memo_id}`);
                }
            }
            return (
                <Container>
                    <div className="w-full mb-8 px-4" id="breadcrumbs">
                        <Breadcrumbs
                            items={getBreadCrumbsItems(memo_subject ? memo_subject : '', props.path, props.memo_id)}
                        />
                    </div>
                    <MemoViewHeader />
                    <MemoViewContent>
                        <MemoViewColumn width="w-full md:w-2/3">
                            <MemoViewContentGeneralInfo id="general-info" aria-label="General info" />
                            <MemoViewContentData id="memo-content" aria-label="Memo Content" />
                            <MemoViewActions className="hidden md:flex" id="actions" aria-label="Actions" />
                        </MemoViewColumn>
                        <MemoViewColumn width="w-full md:w-1/3">
                            {memo.memo_status !== 'SAVE_AS_DRAFT' && (
                                <MemoViewContentLogs id="approver-logs" aria-label="Approver Logs" />
                            )}
                        </MemoViewColumn>
                    </MemoViewContent>
                    <MemoViewActions className="flex md:hidden" id="actions" aria-label="Actions" />
                </Container>
            );
        case 'rejected':
            return (
                <Container>
                    <Title>Error :(</Title>
                    <Subtitle>{cfResponse}</Subtitle>
                </Container>
            );
        default:
            return null;
    }
}

export default MemoView;
