import _ from 'lodash';
import React from 'react';
import Input from '../../../../layouts/form/Input';
import { selectStyles } from '../../../../layouts/select/Select';
import { TemplateConstant, TemplateContent } from '../../../../models/TemplateContent';

interface IFormGridCellAnswerSelectionProps {
    formItem: TemplateContent;
    fieldTypes?: string[];
    onAnswerTypeChanged?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBranchingQuestionToggled?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disableBranchingQuestions?: boolean;
}
const FormGridCellAnswerSelection = (props: IFormGridCellAnswerSelectionProps) => {
    const getFieldName = (fieldType: string) => {
        switch (fieldType) {
            case 'TEXT':
                return 'Text field';
            case 'TEXT_CURRENCY':
                return 'Number field';
            case 'DROPDOWN':
                return 'Dropdown';
            case 'RADIO':
                return 'Radio';
            case 'CHECKBOX':
                return 'Checkbox';
            case 'DATEPICKER':
                return 'Date';
            case 'TIMEPICKER':
                return 'Time';
            case 'FILE_UPLOAD':
                return 'File upload';
            case 'TEXT_RTE':
                return 'Rich-text editor';
            default:
                return '';
        }
    };

    const hasBranchingQuestions = (formItem: TemplateContent) => {
        const value = _.reduce(
            formItem.constant,
            (acc, curr: TemplateConstant) => {
                if (curr.branchingQuestions || curr.branchingSection) {
                    acc = true;
                }
                return acc;
            },
            false,
        );
        return value;
    };

    return (
        <div className="w-full mt-2 flex flex-col">
            <label className="text-sm font-regular" htmlFor="random">
                Answer type
            </label>

            {props.formItem.field_id === 'TEXT_SUBJECT' ? (
                <select
                    style={selectStyles}
                    className="p-2 pr-6 my-1 cursor-pointer w-1/2 outline-none text-sm font-regular cursor-not-allowed"
                    onChange={props.onAnswerTypeChanged}
                    disabled={props.formItem.field_id === 'TEXT_SUBJECT'}
                >
                    <option value="TEXT">Text field</option>
                </select>
            ) : (
                <div className="w-full h-auto flex flex-row items-center">
                    <div className="w-1/2 h-auto">
                        {props.fieldTypes ? (
                            <select
                                style={selectStyles}
                                className="p-2 pr-6 my-1 cursor-pointer w-full outline-none text-sm font-regular"
                                onChange={props.onAnswerTypeChanged}
                                defaultValue={props.formItem.field_type}
                            >
                                {props.fieldTypes.map((fieldType) => {
                                    return (
                                        <option key={fieldType} value={fieldType}>
                                            {getFieldName(fieldType)}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : (
                            <select
                                style={selectStyles}
                                className="p-2 pr-6 my-1 cursor-pointer w-full outline-none text-sm font-regular"
                                onChange={props.onAnswerTypeChanged}
                                defaultValue={props.formItem.field_type}
                            >
                                <option value="TEXT">Text field</option>
                                <option value="TEXT_CURRENCY">Number field</option>
                                <option value="DROPDOWN">Dropdown</option>
                                <option value="RADIO">Radio buttons</option>
                                <option value="CHECKBOX">Checkbox</option>
                                <option value="DATEPICKER">Date</option>
                                <option value="TIMEPICKER">Time</option>
                                <option value="FILE_UPLOAD">File upload</option>
                                <option value="TEXT_RTE">Rich-text editor</option>
                            </select>
                        )}
                    </div>
                    {['DROPDOWN', 'RADIO'].includes(props.formItem.field_type) &&
                        Array.isArray(props.formItem.constant) &&
                        props.formItem.constant.length > 0 &&
                        !props.disableBranchingQuestions && (
                            <div className="w-full h-auto flex items-center justify-end">
                                <Input
                                    id="chb_question"
                                    type="checkbox"
                                    label="Branching question"
                                    annotation="Branching question"
                                    defaultChecked={hasBranchingQuestions(props.formItem)}
                                    onChange={props.onBranchingQuestionToggled}
                                />
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};

export default FormGridCellAnswerSelection;
