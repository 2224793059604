import { Link, RouteComponentProps } from '@reach/router';
import firebase from 'firebase/app';
import React from 'react';
import login_bg from '../../../assets/img/login-bg.png';
import logo_globe from '../../../assets/img/logo_globe.png';
import logo_memo from '../../../assets/img/new_logo_memo.png';
import ButtonSocial from '../../../layouts/buttons/ButtonSocial';
import Carousel from '../../../layouts/carousel/Carousel';
import Tooltip from '../../../layouts/tooltip/Tooltip';
import './Login.css';

interface ILoginProps {
    path?: RouteComponentProps;
}

const Login: React.FunctionComponent<ILoginProps> = () => {
    const authenticate = async (providerType: string) => {
        switch (providerType) {
            case 'google':
                const redirectURI =
                    window.location.hostname === 'localhost'
                        ? `${window.location.protocol}//${window.location.hostname}:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/auth/auth/google`
                        : `${window.location.origin}/auth/google`;
                window.location.replace(redirectURI);
                break;
            case 'microsoft':
                const provider = new firebase.auth.OAuthProvider('microsoft.com');
                provider.setCustomParameters({
                    // Force re-consent.
                    prompt: 'consent',
                });
                await firebase.auth().signInWithPopup(provider);
                break;
            default:
                alert('No provider configured.');
        }
    };

    return (
        <div className="w-full h-full bg-white flex flex-row">
            {/* Login screen */}
            <div className="w-full md:w-1/2 h-full flex flex-row items-center justify-center">
                <div className="w-3/4 md:w-2/4 m-4">
                    <img src={logo_memo} className="lg:w-1/2" alt="memo.ph" />
                    {/* <div className="flex flex-row items-center w-full object-contain mt-5">
                        <p className="text-xs font-regular">Powered by</p>
                        <img src={logo_globe} className="ml-2" alt="Globe" width="60px" />
                    </div> */}

                    <div className="w-full flex flex-col mt-8">
                        <ButtonSocial text="Sign in with Google" provider="google" onClick={authenticate} />
                    </div>

                    <div className="w-full flex flex-row items-center mt-6 relative">
                        <span style={{ height: '1px', background: '#c6c4c4' }} className="w-full" />
                        <p className="text-sm absolute bg-white px-4" style={{ left: '44%', top: '-10px' }}>
                            or
                        </p>
                    </div>

                    <div className="w-full flex flex-row items-center justify-center mt-7 gap-5">
                        <ButtonSocial text="Sign in with Microsoft" provider="microsoft" onClick={authenticate} />
                        <Tooltip type="component">
                            <ButtonSocial text="Sign in with Microsoft" provider="waa" onClick={authenticate} />
                            <span
                                className="tooltiptext absolute text-sm mt-2"
                                role="tooltip"
                                style={{ width: '220px', whiteSpace: 'pre-wrap' }}
                            >
                                New login option coming soon!
                            </span>
                        </Tooltip>
                    </div>

                    <div className="w-full mt-12">
                        <p className="text-xs font-regular">
                            By signing in, you have read, understood, and agreed to Memo.ph’s{' '}
                            <Link to="/terms">
                                <u>Terms & conditions</u>
                            </Link>{' '}
                            and{' '}
                            <Link to="/privacy">
                                <u>Privacy policy</u>
                            </Link>
                            .
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-1/2 h-full hidden md:flex md:flex-col bg-blue-light p-4 relative justify-center">
                <Carousel />
            </div>
        </div>
    );
};

export default Login;
